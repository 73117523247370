export default {
  data() {
    return {
      milesCardOrders: {
        loading: false,
        pagination: {
          page: 1,
          perPage: 10,
          total: 0,
          totalPages: 0,
        },
        details: {},
        list: [],
        filters: {
          users: {
            list: [],
            selected: [],
          },
          status: {
            list: [{
                label: "Pendente",
                value: "Pendente,Pendente de Confirmação",
              },
              {
                label: "Cancelado",
                value: "Cancelado,Pedido Cancelado",
              },
              {
                label: "Concluído",
                value: "Concluído",
              },

            ],
            selected: [],
          },
          period: {
            selected: [],
          },
          price: {
            min: "",
            max: "",
          },
          supplier: "",
          isCanceled: "",
          milesCardId: "",
          operationId: "",
          contractId: "",
          searchTerm: "",
          order: "desc",
          orderBy: "created",
        }
      }
    };
  },
  methods: {
    milesCardOrdersChangePage(current) {
      this.milesCardOrders.pagination.page = current;
      this.get();
    },
    milesCardOrdersChangePageSize(current, pageSize) {
      this.milesCardOrders.pagination.page = current;
      this.milesCardOrders.pagination.perPage = pageSize;
      this.getMilesCardOrders();
    },
    milesCardOrdersTableChange(pagination, filters, sorter) {
      //console.log("sorter s", sorter, pagination, filters);
      this.milesCardOrders.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.milesCardOrders.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getMilesCardOrders();
    },
    getMilesCardOrders(samePage) {

      let filters = "";

      this.milesCardOrders.loading = true;

      if (this.milesCardOrders.filters.searchTerm) {
        filters += `&s=${this.milesCardOrders.filters.searchTerm}`;
      }

      if (this.milesCardOrders.filters.contractId) {
        filters += `&contract_id=${this.milesCardOrders.filters.contractId}`;
      }

      if (this.milesCardOrders.filters.milesCardId) {
        filters += `&milescard_id=${      this.milesCardOrders.filters.milesCardId}`;
      }

      if (this.milesCardOrders.filters.supplier) {
        filters += `&supplier_id=${this.milesCardOrders.filters.supplier}`;
      }

      if (this.milesCardOrders.filters.isCanceled) {
        filters += `&is_canceled=${this.milesCardOrders.filters.isCanceled}`;
      }

      if (this.milesCardOrders.filters.period.selected.length > 0) {
        filters += `&period=${this.milesCardOrders.filters.period.selected[0]}|${this.milesCardOrders.filters.period.selected[1]}`;
      }

      if (this.milesCardOrders.filters.operationId) {
        filters += `&milescard_transaction_id=${this.milesCardOrders.filters.operationId}`;
      }

      if (this.milesCardOrders.filters.status.selected.length > 0) {
        filters += `&status=${this.milesCardOrders.filters.status.selected}`;
      }

      if (this.milesCardOrders.filters.users.selected.length > 0) {
        filters += `&user_id=${this.milesCardOrders.filters.users.selected}`;
      }

      if (this.milesCardOrders.filters.price.min || this.milesCardOrders.filters.price.max) {
        let min = this.milesCardOrders.filters.price.min;
        let max = this.milesCardOrders.filters.price.max;

        if (min) {
          if (!max) {
            max = "9999999999";
          }
        }

        if (max) {
          if (!min) {
            min = "0";
          }
        }

        filters += `&miles-range=${min}-${max}`;
      }

      filters += `&order=${this.milesCardOrders.filters.order}&order-by=${this.milesCardOrders.filters.orderBy}`;

      this.$http
        .get(
          `/miles-card-order/list?page=${this.milesCardOrders.pagination.page}&per_page=${this.milesCardOrders.pagination.perPage}${filters}`
        )
        .then(({
          data
        }) => {
          if (!samePage) {
            this.milesCardOrders.pagination.page = 1;
          }
          this.milesCardOrders.pagination.total = data.meta.total;
          this.milesCardOrders.pagination.totalPages = data.meta.total_pages;
          this.milesCardOrders.list = data.data;
          this.milesCardOrders.loading = false;

          this.milesCardOrders.meta = data;

          this.excelFile.data = data.data;

          this.excelFile.footer = [];

          this.excelFile.footer.push(" ");

          this.excelFile.footer.push(
            `TOTAL DE MILHAS: ${data.meta.total_miles}`
          );

          this.excelFile.footer.push(
            `VALOR: R$ ${data.meta.total_value}`
          );

          if (data.meta.total != 0) {
            this.excelFile.footer.push(
              `N° TRANSAÇÕES:  ${data.meta.total}`
            );
          }

          if (data.meta.total_emissions != 0) {
            this.excelFile.footer.push(
              `EMISSÕES:  ${data.meta.total_emissions}`
            );
          }

          if (data.meta.total_cancelations != 0) {
            this.excelFile.footer.push(
              `CANCELAMENTOS:  ${data.meta.total_cancelations}`
            );
          }

          if (data.meta.total_new != 0) {
            this.excelFile.footer.push(
              `NOVAS COMPRAS:  ${data.meta.total_new}`
            );
          }

          if (data.meta.total_additions != 0) {
            this.excelFile.footer.push(
              `ADIÇÕES:  ${data.meta.total_additions}`
            );
          }

          if (data.meta.total_refunds != 0) {
            this.excelFile.footer.push(
              `REEMBOLSOS:  ${data.meta.total_refunds}`
            );
          }

        })
        .catch(({
          response
        }) => {
          this.milesCardOrders.loading = false;
          this.milesCardOrders.list = response.data.data;
          this.pagination.total = response.data.meta.total;
          this.pagination.totalPages = response.data.meta.total_pages;

          this.milesCardOrders.meta = response.data.meta;

          this.excelFile.data = response.data.data;
        });
    },

  }

}