<template>
  <div id="miles-cards-emissions" class="pd-20">
    <a-page-header
      class="pd-0"
      sub-title="Connect Miles"
      @back="() => $router.go(-1)"
    >
      <h1 slot="title">Relatório de Taxas</h1>

      <div slot="extra">
        <downloadExcel
          v-if="$root.isAdmin()"
          class="btn btn-default"
          :header="excelFile.header"
          :name="excelFile.fileName"
          :data="excelFile.data"
          :fields="excelFile.collumns"
          :footer="excelFile.footer"
          style="display: inline-block"
        >
          <img
            class="c-pointer ml-10"
            src="@/assets/images/dashboard/excel.png"
          />
        </downloadExcel>
      </div>
    </a-page-header>

    <a-row class="haya-panels" :gutter="20">
      <a-col :span="6">
        <div class="box">
          <div class="title"></div>
          <div class="total cblue">
            {{ milesCardOrders.meta.totalMiles }}
          </div>
          <div class="txt">Total de Milhas</div>
          <div class="footer">
            Valor: R$ {{ milesCardOrders.meta.totalValue }}
          </div>
        </div>
      </a-col>
    </a-row>

    <a-collapse
      class="travel-filters expandable mt-20 mb-10"
      activeKey="0"
      expandIconPosition="right"
    >
      <a-collapse-panel key="1">
        <template slot="header">
          <a-icon type="filter" class="mr-5" /> FILTRAR
        </template>

        <a-row
          class="mt-5"
          :gutter="20"
          style="margin-left: 0; margin-right: 0"
        >
          <a-col :span="2">
            <div class="travel-input">
              <label class="filled">ID do Contrato</label>
              <a-input
                placeholder="Digite"
                v-model="milesCardOrders.filters.contractId"
                @change="getMilesCardOrders()"
              />
            </div>
          </a-col>

          <a-col :span="4">
            <div class="travel-input">
              <label class="filled">Status</label>
              <a-select
                class="travel-input"
                placeholder="Selecione"
                mode="multiple"
                v-model="milesCardOrders.filters.status.selected"
                show-search
                style="width: 100%"
                @change="getMilesCardOrders()"
              >
                <a-select-option
                  v-for="(item, index) of milesCardOrders.filters.status.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>


          <a-col v-if="$root.isAdmin()" :span="6">
            <div class="travel-input">
              <label class="filled">Usuários</label>
              <a-select
                class="travel-input"
                placeholder="Selecione um ou mais usuários"
                optionFilterProp="txt"
                mode="multiple"
                v-model="milesCardOrders.filters.users.selected"
                show-search
                style="width: 100%"
                @change="getMilesCardOrders()"
              >
                <a-select-option
                  v-for="(item, index) of listUsers"
                  :key="index"
                  :value="item.id"
                  :txt="item.first_name"
                >
                  <div v-if="item.id == -1">
                    {{ item.first_name }}
                    {{ item.last_name }}
                  </div>
                  <div v-else>
                    {{ item.id }} -
                    {{ item.first_name }}
                    {{ item.last_name }}
                  </div>
                </a-select-option>
              </a-select>
            </div>
          </a-col>
          <a-col :span="6">
            <div class="travel-input">
              <label class="filled">Período</label>
              <a-range-picker
                v-model="milesCardOrders.filters.period.selected"
                :format="['DD/MM/YYYY']"
                :value-format="'YYYY-MM-DD'"
                @change="getMilesCardOrders"
              />
            </div>
          </a-col>
        </a-row>
      </a-collapse-panel>
    </a-collapse>

    <div class="travel-table">
      <HayaTable
        :columns="[
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            class: 'id',
            width: 60,
          },
          {
            title: '#',
            key: 'contract_id',
            width: 60,
            scopedSlots: { customRender: 'contract_id' },
          },
          {
            title: 'Milhas',
            dataIndex: 'miles_quantity',
            key: 'miles_quantity',
            width: 110,
            sorter: true,
          },
          {
            title: 'Custo',
            scopedSlots: { customRender: 'miles_cost' },
            key: 'miles_cost',
            width: 110,
            sorter: true,
          },
          {
            title: '/1000',
            scopedSlots: { customRender: 'per_miles' },
            key: 'per_miles',
            width: 90,
            //sorter: true,
          },

          {
            title: 'Valor Tkt.',
            scopedSlots: { customRender: 'value' },
            key: 'value',
            width: 110,
            sorter: true,
          },
          {
            title: 'Venda',
            scopedSlots: { customRender: 'sale' },
            key: 'sale',
            width: 110,
            // sorter: true,
          },
          {
            title: 'Lucro',
            scopedSlots: { customRender: 'profit' },
            key: 'profit',
            width: 115,
            class: 'available-miles profit',
            //sorter: true,
          },
          {
            title: 'Taxa Tkt.',
            scopedSlots: { customRender: 'tax_value' },
            key: 'tax_value',
            width: 110,
          },
          {
            title: 'Pgm.',
            scopedSlots: { customRender: 'miles_program' },
            width: 90,
          },
          {
            title: 'Cartão',
            key: 'miles_card',
            scopedSlots: { customRender: 'miles_card' },
            width: 80,
          },
          {
            title: 'Fornecedor',
            scopedSlots: { customRender: 'supplier' },
            width: 100,
          },
          {
            title: 'Usuário',
            dataIndex: 'user',
            key: 'user',
            scopedSlots: { customRender: 'user' },
            width: 100,
          },
          {
            title: 'Confirmação ',
            scopedSlots: { customRender: 'modified' },
            key: 'modified',
            width: '140px',
            sorter: true,
          },
        ]"
        :data-source="milesCardOrders.list"
        :loading="milesCardOrders.loading"
        :pagination="false"
        :paginator="milesCardOrders.pagination"
        rowKey="id"
        @getList="getMilesCardOrders()"
        @change="milesCardOrdersTableChange"
      >
        <div slot="id" slot-scope="text">{{ text }}</div>
        <div slot="contract_id" slot-scope="record">
          <a
            v-if="$root.isAdmin()"
            style="padding: 0; font-size: 11px; height: auto"
            :href="`/contracts/edit/${record.contract_id}`"
            target="_blank"
          >
            {{ record.contract_id }}
          </a>

          <div v-if="!$root.isAdmin()">
            {{ record.contract_id }}
          </div>
        </div>

        <div slot="miles_cost" slot-scope="record">
          {{ formatPricePtBr(record.miles_cost) }}
        </div>

        <div slot="per_miles" slot-scope="record">
          R$ {{ record.per_miles }}
        </div>

        <div slot="profit" slot-scope="record">
          <div v-html="formatProfit(record)" />
        </div>

        <div slot="sale" slot-scope="record">R$ {{ record.sale }}</div>

        <div slot="miles_program" slot-scope="record">
          {{ record.miles_program ? record.miles_program.name : "-" }}
        </div>
        <div slot="module" slot-scope="record">
          {{ record }}
        </div>
        <div slot="value" slot-scope="record">
          R$
          {{
            record.flight_value == "0,00" ? record.value : record.flight_value
          }}
        </div>
        <div slot="tax_value" slot-scope="record">
          {{ record.tax_currency ? formatCurrency(record.tax_currency) : "-" }}
          {{ record.tax_value }}
        </div>
        <div slot="user" slot-scope="record">
          {{ record.first_name }} {{ record.last_name }}
        </div>
        <div slot="miles_card" slot-scope="record">
          <div v-html="record ? formatMilesRequest(record) : '-'"></div>
        </div>
        <div slot="supplier" slot-scope="record">
          {{
            record.miles_card
              ? record.miles_card.supplier.first_name +
                " " +
                record.miles_card.supplier.last_name
              : "- "
          }}
        </div>
        <div slot="modified" slot-scope="record">
          {{
            record.status == "Pendente"
              ? "A confirmar emissão"
              : formatDateTime(record.modified)
          }}
        </div>
        <div slot="created" slot-scope="record">
          {{ formatDateTime(record) }}
        </div>
      </HayaTable>
    </div>
  </div>
</template>

<script>
import HayaTable from "@/components/general/HayaTable.vue";
import milesCardsOrdersMixins from "@/mixins/miles-cards-orders/milesCardsOrdersMixins";
import formatThings from "@/mixins/general/formatThings.js";

export default {
  components: { HayaTable },
  mixins: [milesCardsOrdersMixins, formatThings],
  data() {
    return {
      excelFile: {
        header: "RELATÓRIO DE TAXAS",
        fileName: "RELATÓRIO DE TAXAS.xls",
        collumns: {
          ID: "id",
          "ID Contrato": "contract_id",
          Milhas: "miles_quantity",
          Custo: {
            field: "miles_cost",
            callback: (miles) => {
              return `R$ ${miles}`;
            },
          },
          "/1000": {
            field: "per_miles",
            callback: (miles) => {
              return `R$ ${this.formatPrice(miles)}`;
            },
          },
          "Valor Ticket": {
            field: "flight_value",
            callback: (value) => {
              return `R$ ${value}`;
            },
          },
          Venda: {
            field: "sale",
            callback: (value) => {
              return `R$ ${value}`;
            },
          },
          Lucro: {
            field: "profit",
            callback: (value) => {
              return `R$ ${value}`;
            },
          },
          "Taxa Ticket": {
            field: "tax_value",
            callback: (value) => {
              return `R$ ${value}`;
            },
          },
          Programa: {
            field: "miles_card",
            callback: (card) => {
              return `${card.miles_program}`;
            },
          },
          Cartão: {
            field: "miles_card",
            callback: (card) => {
              return `ID ${card.id}`;
            },
          },
          Fornecedor: {
            field: "miles_card",
            callback: (card) => {
              return `${card.supplier.first_name} ${card.supplier.last_name}`;
            },
          },
          Usuário: {
            field: "user",
            callback: (user) => {
              return `${user.first_name} ${user.last_name}`;
            },
          },
          Confirmação: "modified",
        },
        data: [],
        footer: [],
      },
      listUsers: [],
    };
  },
  mounted() {
    this.getMilesCardOrders();

    this.$http.get("/user/list?page=1&per_page=100").then(({ data }) => {
      this.listUsers = data.data;
    });
  },
  setup() {
    document.title = "Relatório de Taxas - HAYA";
  },
  methods: {
    formatMilesRequest(request) {
      let allCards = "";

      if (
        request.status == "Pendente" ||
        request.status == "Pedido Cancelado"
      ) {
        allCards = "";
      } else {
        allCards = `ID ${request.miles_card.id}`;
      }

      return allCards;
    },
    formatProfit(record) {
      let divClass = "";
      if (parseFloat(record.raw.profit) < 0) {
        divClass = "red";
      }

      return `<div class="${divClass}"> R$ ${record.profit} </div>`;
    },
  },
};
</script>
